import { Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import {
	screenThreshold,
	sectionHeader,
	sectionHeaderInitial,
	sectionHeaderTransition
} from '../utils/animationVariants';
import { useInView } from 'react-intersection-observer';

const StyledTypography = styled(Typography)`
	font-weight: 1000;
    margin-bottom: 32px;
`;
const SectionHeader = ({ text, inView }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

	const animation = useAnimation();
	useEffect(
		() => {
			if (inView) {
				animation.start(sectionHeader);
			}
			if (!inView) {
				animation.start(sectionHeaderInitial);
			}
		},
		[ inView ]
	);
	return (
		<motion.div animate={animation} transition={sectionHeaderTransition} initial={sectionHeaderInitial}>
			<StyledTypography
				variant={isMdSmall ? 'h5' : 'h4'}
				color="textSecondary"
				gutterBottom
				align="center"
				className="sectionHeader"
			>
				{text}
			</StyledTypography>
		</motion.div>
	);
};

export default SectionHeader;
