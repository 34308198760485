import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, useMediaQuery, Grid } from '@material-ui/core';

import { cardItem, cardList, navItem, screenThreshold } from '../utils/animationVariants';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));

// install Swiper modules
SwiperCore.use([ Pagination, Autoplay ]);

const ServiceSlide = ({ service, index }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isSmSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	const navListVisible = useAnimation();
	const navItemVisible = useAnimation();
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				navListVisible.start('visible');
				navItemVisible.start('visible');

				cardListVisible.start('visible');
				cardItemVisible.start('visible');
			}
			if (!inView) {
				navListVisible.start('hidden');
				navItemVisible.start('hidden');

				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);
	const { imageURL, name, infoList, imageSlider } = service;
	const [ active, setActive ] = useState(0);
	return (
		<Card className="slideCard" ref={ref} elevation={0}>
			<MotionGrid
				container
				justifyContent="center"
				alignItems="center"
				initial="hidden"
				animate={cardListVisible}
				variants={cardList}
			>
				<MotionGrid
					item
					md={6}
					sm={6}
					xs={12}
					initial="hidden"
					animate={cardItemVisible}
					variants={cardItem}
					custom={1}
				>
					<div className={`innerSwiper media-wrapper-${index}`}>
						<Swiper
							className="mySwiper2 swiper-v"
							direction={'vertical'}
							slidesPerView={1}
							autoplay={{ delay: 2000 }}
							spaceBetween={10}
							loop={imageSlider.length > 1 ? true : false}
							pagination={
								isSmSmall ? (
									false
								) : (
									{
										clickable: true
									}
								)
							}
							navigation={false}
							onSlideChange={(slide) => {
								setActive(slide.realIndex);
								// console.log(typeof slide.realIndex);
							}}
							// onSwiper={(swiper) => console.log(swiper)}
						>
							{imageSlider.map(({ img, title }, i) => (
								<SwiperSlide key={i}>
									<div className="innerSwiperMediaContainer">
										<CardMedia
											className="innerSlideCardMedia"
											component="img"
											alt={title}
											image={img}
											title={title}
										/>
										<Typography component="h6" className="imageTitle">
											<span className="sliderImageTitle">{title}</span>
										</Typography>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<Typography className="slideTitle" align="center" variant={isMdSmall ? 'body2' : 'h6'}>
						{name}
					</Typography>
				</MotionGrid>
				{isSmSmall ? null : <MotionGrid item md={1} />}
				<MotionGrid
					item
					md={isSmSmall ? 6 : 5}
					sm={6}
					xs={12}
					initial="hidden"
					animate={cardItemVisible}
					variants={cardItem}
					custom={2}
				>
					<CardContent>
						{infoList.map(({ text, icon }, i) => (
							<div
								className="slideTextItem"
								custom={i}
								key={i}
								initial="hidden"
								animate={navItemVisible}
								variants={navItem}
							>
								<img src={icon} width="100px" height="20px" />
								<Typography
									variant={isSmSmall ? 'subtitle2' : 'subtitle1'}
									component="p"
									key={i}
									gutterBottom
									style={
										i === active && index !== 4 ? { fontWeight: 'bold' } : { fontWeight: 'normal' }
									}
									color={i === active && index !== 4 ? 'textSecondary' : 'textPrimary'}
								>
									{text}
								</Typography>
							</div>
						))}
					</CardContent>
				</MotionGrid>
			</MotionGrid>
		</Card>
	);
};

export default ServiceSlide;
