import React, { useEffect } from 'react';
import { Button, Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { cardItem, cardList, screenThreshold } from '../utils/animationVariants';
import SectionHeader from './SectionHeader';
import HiringSVG from './HiringSVG.js';
import { CareerTextLogo } from './Logo';
import { careerData } from '../data';

const CareerFlexBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const FlexTypography = styled(Typography)`
	display: flex;
	align-items: center;
    /* @media(max-width:500px){
        flex-direction: column;
    } */
`;
const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));

const Contact = ({ setActive }) => {
	useEffect(() => {
		document.addEventListener('DOMContentLoaded', function() {
			document.querySelector('svg').classList.add('animated');
		});
	}, []);
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isSmSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				cardListVisible.start('visible');
				cardItemVisible.start('visible');
				setActive('/#career');
			}
			if (!inView) {
				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);
	return (
		<section id="career" ref={ref}>
			<Container>
				<SectionHeader text="Career" inView={inView} />
				<MotionGrid
					container
					spacing={4}
					justifyContent="center"
					alignItems="center"
					variants={cardList}
					initial="hidden"
					animate={cardListVisible}
				>
					<MotionGrid
						item
						lg={6}
						sm={7}
						xs={12}
						variants={cardItem}
						initial="hidden"
						animate={cardItemVisible}
					>
						<CareerFlexBox>
							<div>
								<FlexTypography
									gutterBottom
									variant={isSmSmall ? 'h5' : 'h4'}
									align="center"
									color="textSecondary"
								>
									<span>Interested in</span>
									<span>
										<CareerTextLogo className="careerTextLogo" />?
									</span>
								</FlexTypography>
								<Typography
									variant={isSmSmall ? 'subtitle1' : 'h6'}
									align="center"
									gutterBottom
									color="textSecondary"
								>
									Email us your resume to
								</Typography>
								<Typography align="center" style={{ marginTop: '1rem' }}>
									<Button
										startIcon={<i className="fas fa-envelope" />}
										style={{ textTransform: 'none' }}
										target="_blank"
										href={careerData['email_link']}
										variant="contained"
										color="secondary"
									>
										{careerData['display_email']}
									</Button>
								</Typography>
							</div>
						</CareerFlexBox>
					</MotionGrid>
					<MotionGrid
						item
						lg={6}
						sm={5}
						xs={12}
						variants={cardItem}
						initial="hidden"
						animate={cardItemVisible}
					>
						<HiringSVG />
					</MotionGrid>
				</MotionGrid>
			</Container>
		</section>
	);
};

export default Contact;
