import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';

import { cardList, cardItem, cardHover, cardTap, screenThreshold } from '../utils/animationVariants';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { secondServices } from '../data';
import ServiceBox from './ServiceBox';
import styled from 'styled-components';

const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));

const StyledTypography = styled(Typography)`
	background:var(--light-slate);
	padding:8px 16px;
	border-radius:16px;
	width:fit-content;
    margin-bottom:24px;
    font-family: 'Open Sans','Comfortaa',Arial, Helvetica, sans-serif;
	
`;
// (Professional speaker with sutidio quality)
const ServiceBoxList = ({ setActive }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				cardListVisible.start('visible');
				cardItemVisible.start('visible');
				setActive('/#services');
			}
			if (!inView) {
				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);

	return (
		<Container ref={ref}>
			<MotionGrid
				justifyContent={isXsSmall ? 'center' : 'space-around'}
				// alignItems="center"
				container
				spacing={3}
				initial="hidden"
				animate={cardListVisible}
				variants={cardList}
			>
				{secondServices.map((service, i) => (
					<MotionGrid
						style={{ maxWidth: '380px', marginBottom: '32px' }}
						item
						md={4}
						sm={6}
						key={i}
						custom={i}
						initial="hidden"
						animate={cardItemVisible}
						variants={cardItem}
						whileHover={cardHover}
						whileTap={cardTap}
					>
						<ServiceBox service={service} />
					</MotionGrid>
				))}
				<MotionGrid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
					<StyledTypography align="center" variant="body1" component="p">
						Anything left, you can suggest to add?
					</StyledTypography>
				</MotionGrid>
			</MotionGrid>
		</Container>
	);
};

export default ServiceBoxList;
