import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import headerImageUrl from '../../static/financial_data.png';
import { motion, useAnimation } from 'framer-motion';
import { cardItem, cardList, headerPoints, screenThreshold } from '../utils/animationVariants';
import { wrap } from 'popmotion';
// import AnimatedLetters from './AnimatedLetters';
import { useInView } from 'react-intersection-observer';

// import Typewriter from 'typewriter-effect';
import TextSlider from './TextSlider';
import WordSlider from './WordSlider';
import { ROOT_URL } from '../utils/constants';
import { headerData } from '../data';
// import HeaderParticles from './HeaderParticles';

const StyledTypography = styled(Typography)`
	color:var(--teal-green);
	max-width:30ch;
`;

const FlexColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
`;
const MotionTypography = motion(React.forwardRef((props, ref) => <StyledTypography ref={ref} {...props} />));
const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));

const Header = ({ setActive }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
	const isLgSmall = useMediaQuery((theme) => theme.breakpoints.down('lg'));

	// const HeaderImage = styled.img`isMdSmall? width: 130:`;

	const { ref, inView } = useInView({ threshold: isMdSmall ? 0.1 : screenThreshold });
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				cardListVisible.start('visible');
				cardItemVisible.start('visible');
				setActive('/');
			}
			if (!inView) {
				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);

	const [ [ page, I ], setPage ] = useState([ 0, 0 ]);

	const lineIndex = wrap(0, headerData.subPoints.length, page);

	const paginate = (newI) => {
		setPage([ page + newI, newI ]);
	};

	// console.log('page : ', page, 'I : ', I, 'lineIndex :', lineIndex);

	return (
		<section id="header" ref={ref}>
			{/* <HeaderParticles /> */}
			<Container>
				<MotionGrid
					variants={cardList}
					initial="hidden"
					animate={cardListVisible}
					container
					// spacing={2}
					justifyContent="center"
					alignItems={isMdSmall ? 'center' : 'center'}
					style={{ minHeight: '80vh' }}
				>
					{/* {isMdSmall && <MotionGrid item xs={12} style={{ height: '80px' }} />} */}
					<MotionGrid item xs={12} style={{ height: '60px' }} />
					<MotionGrid
						item
						md={10}
						sm={12}
						variants={cardItem}
						initial="hidden"
						animate={cardItemVisible}
						custom={0}
					>
						<Typography
							align="center"
							className="main-heading"
							variant={isMdSmall ? 'h4' : 'h2'}
							gutterBottom
						>
							{isXsSmall ? (
								<span className="firstVerticle">
									{headerData.title[0]}
									<WordSlider />
								</span>
							) : (
								<span className="first">
									{headerData.title[0]}
									<WordSlider />
								</span>
							)}

							{headerData.title[1]}
							{headerData.title[2]}
						</Typography>
					</MotionGrid>
					<MotionGrid
						item
						md={10}
						xs={12}
						variants={cardItem}
						initial="hidden"
						animate={cardItemVisible}
						custom={1}
					>
						<Typography variant="h5" align="center">
							{headerData.points[0]}
						</Typography>
						<Typography variant="h5" align="center" gutterBottom>
							{headerData.points[1]}
						</Typography>
					</MotionGrid>
					<MotionGrid
						item
						md={10}
						xs={12}
						variants={cardItem}
						initial="hidden"
						animate={cardItemVisible}
						custom={2}
					>
						<TextSlider subPoints={headerData.subPoints} />
					</MotionGrid>
				</MotionGrid>
			</Container>
		</section>
	);
};

export default Header;
