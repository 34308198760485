import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper/core';
import { wordsData } from '../data';

// install Swiper modules
SwiperCore.use([ Autoplay ]);

const WordSlider = () => {
	return (
		<Swiper
			direction={'vertical'}
			slidesPerView={1}
			autoplay={{ delay: 1000 }}
			spaceBetween={30}
			loop={true}
			className="mySwiper"
		>
			{wordsData.map((word, i) => (
				<SwiperSlide key={i}>
					<span style={{ color: word.color }}>{word.text}</span>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default WordSlider;
