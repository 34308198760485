import React from 'react';
import Typography from '@material-ui/core/Typography';

import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { useMediaQuery } from '@material-ui/core';

// install Swiper modules
SwiperCore.use([ Pagination, Autoplay ]);

const TextSlider = ({ subPoints }) => {
	const isSmSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	return (
		<div className="text-slider">
			<Swiper
				slidesPerView={1}
				autoplay={{ delay: 5000 }}
				spaceBetween={30}
				loop={true}
				pagination={{
					clickable: true
				}}
				navigation={false}
				className="mySwiper"
			>
				{subPoints.map((subPoint, index) => {
					return (
						<SwiperSlide key={index}>
							<Typography className="textSliderPoint" align="center" variant={isSmSmall ? 'h5' : 'h4'}>
								{subPoint}
							</Typography>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default TextSlider;
