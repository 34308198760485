import { useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';

import SectionHeader from './SectionHeader';
import { ServicesSlider } from '.';
import WhiteSpace from './WhiteSpace';
import ServiceBoxList from './ServiceBoxList';
import { useInView } from 'react-intersection-observer';
import { screenThreshold } from '../utils/animationVariants';
import { motion } from 'framer-motion';
import ServicesTimeline from './ServicesTimeline';

// (Professional speaker with sutidio quality)
const Services = ({ setActive }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	useEffect(
		() => {
			if (inView) {
				setActive('/#services');
			}
		},
		[ inView ]
	);
	return (
		<React.Fragment>
			<section id="services" ref={ref}>
				{/* <WhiteSpace /> */}
				<SectionHeader text="Services" inView={inView} />
				<ServicesSlider />
			</section>
			<motion.section // 	duration: 3 // transition={{ // animate={{ backgroundColor: '#436bda' }}
			// }}
			id="second-services">
				<ServicesTimeline setActive={setActive} />
				{/* <ServiceBoxList setActive={setActive} /> */}
			</motion.section>
		</React.Fragment>
	);
};

export default Services;
