import styled from 'styled-components';

const WhiteSpace = styled.div`
	height: 80px;
	background-color: var(--lightNavy);

	@media (max-width: 768px) {
		height: 70px;
	}
`;

export default WhiteSpace;
