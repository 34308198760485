import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { motion } from 'framer-motion';

//MUi stuff
import { makeStyles } from '@material-ui/core/styles';
import {
	AppBar,
	Toolbar,
	Typography,
	Container,
	SwipeableDrawer,
	Divider,
	IconButton,
	MenuList,
	MenuItem,
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
	LinearProgress,
	useMediaQuery
} from '@material-ui/core';

import { navList, navItem } from '../utils/animationVariants';
import Logo from './Logo';
import { darkNavy, lightNavy, tealGreen } from '../styles/variables';
import { green } from '@material-ui/core/colors';
import { navLinks } from '../data';

const NavList = styled.nav`
	ol {
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		margin: 0;
		padding-top: 16px;
	}
	li {
		margin: 0 5px;
		font-size: var(--fz-xs);
		/* min-width: 80px; */
		a {
			padding: 5px 20px;
			text-decoration: none;
			display: block;
			@media (max-width: 1024px) {
				padding: 5px 16px;
			}
			&:before {
				margin: 0 5px;
				text-align: right;
			}
			&:focus > h6 {
				color: var(--teal-green);
			}
			&:hover > h6 {
				color: var(--teal-green);
			}
		}

		&:last-child > a {
			margin-right: 0px;
			padding-right: 0px;
		}
	}
`;

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		background: lightNavy,
		boxShadow: 'none',
		paddingTop: theme.spacing(0.5),
		color: darkNavy
	},
	linearProgress: {
		minHeight: theme.spacing(0.25),
		maxHeight: theme.spacing(0.25)
	},
	menuButton: {
		marginLeft: theme.spacing(2)
	},
	userMenu: {
		margin: theme.spacing(-0.5, 0, -0.5, 0)
	},
	title: {
		flexGrow: 1,
		// color: theme.palette.primary.main,
		fontSize: '30',
		fontWeight: '500',
		marginLeft: theme.spacing(-1.5)
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1)
	},
	toolbar: theme.mixins.toolbar,
	tool: {
		padding: theme.spacing(0),
		display: 'flex',
		justifyContent: 'space-between'
	},
	drawerPaper: {
		minWidth: drawerWidth
	},
	logo: {
		marginRight: theme.spacing(0),
		maxHeight: '64px'
	},
	listItemAvatar: {
		minWidth: theme.spacing(4),
		paddingTop: theme.spacing(0.5)
	}
}));

const MotionMenuList = motion(React.forwardRef((props, ref) => <MenuList ref={ref} {...props} />));
const MotionMenuItem = motion(React.forwardRef((props, ref) => <MenuItem ref={ref} {...props} />));
const MotionTypography = motion(React.forwardRef((props, ref) => <Typography ref={ref} {...props} />));

const Nav = ({ active, setActive }) => {
	const isMdGreater = useMediaQuery((theme) => theme.breakpoints.up('md'));
	const isSmSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

	const classes = useStyles();
	const [ state, setState ] = useState({
		left: false
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const drawer = (
		<div
			className={classes.drawerPaper}
			role="presentation"
			onClick={toggleDrawer('left', false)}
			onKeyDown={toggleDrawer('left', false)}
		>
			<ListItem component={Link} to="/login" style={{ maxHeight: '68px' }}>
				<Logo />
			</ListItem>

			<Divider />
			<MotionMenuList initial="hidden" animate="visible" variants={navList} style={{ paddingTop: 0 }}>
				{navLinks.map(({ name, url }, i) => (
					<MotionMenuItem
						component={Link}
						to={url}
						key={i}
						custom={i}
						initial="hidden"
						animate="visible"
						variants={navItem}
						whileHover={{ color: 'darkgreen', x: 10 }}
						className={active === url ? 'activeMenuItem' : 'menuItem'}
					>
						<MotionTypography variant="subtitle1" className={active === url ? 'active' : ''}>
							{name}
						</MotionTypography>
					</MotionMenuItem>
				))}
			</MotionMenuList>
		</div>
	);

	return (
		<Fragment>
			<nav>
				<AppBar component="div" position="fixed" className={classes.appBar}>
					<Container>
						<Toolbar className={classes.tool}>
							<Logo />
							{isMdGreater && (
								<NavList>
									<motion.ol initial="hidden" animate="visible" variants={navList}>
										{navLinks.map(({ name, url }, i) => (
											<motion.li
												key={i}
												custom={i}
												initial="hidden"
												animate="visible"
												variants={navItem}
											>
												<Link to={url}>
													<MotionTypography
														variant="subtitle1"
														onClick={() => setActive(url)}
														className={active === url ? 'active' : ''}
													>
														{name}
													</MotionTypography>
												</Link>
											</motion.li>
										))}
									</motion.ol>
								</NavList>
							)}
							{isSmSmall && (
								<IconButton
									edge="start"
									className={classes.menuButton}
									color="inherit"
									aria-label="menu"
									onClick={toggleDrawer('left', true)}
								>
									<i className="fas fa-align-right text-right " />
								</IconButton>
							)}
						</Toolbar>
					</Container>
				</AppBar>
				<SwipeableDrawer
					anchor={'left'}
					open={state['left']}
					onClose={toggleDrawer('left', false)}
					onOpen={toggleDrawer('left', true)}
				>
					{drawer}
				</SwipeableDrawer>
			</nav>
			<div className={classes.toolbar} />
			<div className={classes.content} />
		</Fragment>
	);
};

export default Nav;
