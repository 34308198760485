import { Container, Grid, useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import SectionHeader from './SectionHeader';
import TeamCard from './TeamCard';
import { cardList, cardItem, cardHover, cardTap, screenThreshold } from '../utils/animationVariants';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TeamData } from '../data';

const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));

const Team = ({ setActive }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
	const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				cardListVisible.start('visible');
				cardItemVisible.start('visible');
				setActive('/#team');
			}
			if (!inView) {
				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);

	return (
		<motion.section id="team" ref={ref}>
			<Container>
				<SectionHeader text="Team" inView={inView} />
				<MotionGrid
					container
					spacing={3}
					justifyContent={isXsSmall ? 'center' : 'space-around'}
					alignItems="center"
					initial="hidden"
					animate={cardListVisible}
					variants={cardList}
				>
					{TeamData.map((teamMember, i) => (
						<MotionGrid
							style={{ maxWidth: '340px', marginBottom: '32px' }}
							item
							md={4}
							sm={6}
							key={i}
							custom={i}
							initial="hidden"
							animate={cardItemVisible}
							variants={cardItem}
							whileHover={cardHover}
							whileTap={cardTap}
						>
							<TeamCard teamMember={teamMember} />
						</MotionGrid>
					))}
				</MotionGrid>
			</Container>
		</motion.section>
	);
};

export default Team;
