import React, { useEffect } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import contactImageURL from '../../static/listening.svg';
import { cardItem, cardList, screenThreshold } from '../utils/animationVariants';
import ContactForm from './ContactForm';
import SectionHeader from './SectionHeader';

const StyledImage = styled.img`width: 100%;`;
const MotionGrid = motion(React.forwardRef((props, ref) => <Grid ref={ref} {...props} />));

const Contact = ({ setActive }) => {
	const isMdSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

	const { ref, inView } = useInView({ threshold: isMdSmall ? '0.1' : screenThreshold });
	const cardListVisible = useAnimation();
	const cardItemVisible = useAnimation();
	useEffect(
		() => {
			if (inView) {
				cardListVisible.start('visible');
				cardItemVisible.start('visible');
				setActive('/#contact_us');
			}
			if (!inView) {
				cardListVisible.start('hidden');
				cardItemVisible.start('hidden');
			}
		},
		[ inView ]
	);
	return (
		<section id="contact_us" ref={ref}>
			<Container>
				<SectionHeader text="Contact Us" inView={inView} />
				<MotionGrid
					container
					spacing={4}
					justifyContent="center"
					alignItems="center"
					variants={cardList}
					initial="hidden"
					animate={cardListVisible}
				>
					<MotionGrid item sm={6} variants={cardItem} initial="hidden" animate={cardItemVisible}>
						<StyledImage src={contactImageURL} />
					</MotionGrid>
					<MotionGrid item sm={6} variants={cardItem} initial="hidden" animate={cardItemVisible}>
						<ContactForm inView={inView} />
					</MotionGrid>
				</MotionGrid>
			</Container>
		</section>
	);
};

export default Contact;
