import { css } from 'styled-components';

import swiper from 'swiper/swiper.min.css';
import paginationStyles from 'swiper/components/pagination/pagination.min.css';
import navigationStyles from 'swiper/components/navigation/navigation.min.css';
const swiperStyles = css`
	${swiper};
	${paginationStyles};
	${navigationStyles};
	.main-heading .swiper-container {
		/* width: 10ch; */
		height: 2ch;
		margin-left: 12px;
		margin-right: unset;
		text-align: left;
		@media (max-width: 768px) {
			margin-left: 8px;
		}
		@media (max-width: 500px) {
			text-align: center;
			margin-left: 0px;
		}
	}

	.main-heading .first {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.main-heading .firstVerticle {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.service-slider {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.service-slider .swiper-container {
		max-width: 100%;
	}
	.text-slider .textSliderPoint {
		color: var(--teal-green);
		overflow-y: hidden;
		max-width: 100vw;
		padding-bottom: 30px;
		font-family: "Open Sans", "Comfortaa", Arial, Helvetica, sans-serif;
	}

	.text-slider {
		.wrap {
			box-sizing: border-box;
		}
		#content {
			box-sizing: border-box;
		}
		.swiper-container {
			max-height: 100%;
		}
	}

	.service-slider .swiper-pagination-bullet {
		width: 1rem;
		height: 1rem;
		text-align: center;
		line-height: 20px;
		font-size: 12px;
		color: #000;
		opacity: 1;
		background: var(--light-slate);
		/* @media (max-width: 500px) {
			width: 15px;
			height: 15px;
		} */
	}
	.service-slider .swiper-container-horizontal > .swiper-pagination-bullets {
		bottom: 0px;
	}

	.service-slider .swiper-pagination-bullet-active,
	.text-slider .swiper-pagination-bullet-active {
		color: #fff;
		background: var(--dark-navy);
	}
	.imageTitle {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 8px;
		@media (max-width: 768px) {
			top: 0px;
		}
	}
	.sliderImageTitle {
		padding: 8px 16px;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		background: var(--light-less-slate);
		@media (max-width: 768px) {
			font-size: 14px;
			padding: 4px 8px;
		}
	}
	.innerSwiper {
		border-radius: 30px;
		padding: 15px;
		background-color: var(--teal-green);
		height: 45vh;
		@media (max-width: 500px) {
			padding: 10px;
		}

		@media (max-width: 1024px) {
			height: 40vh;
		}
		@media (max-width: 500px) {
			height: 25vh;
		}
	}
	.innerSwiperMediaContainer {
		position: relative;
		height: 100%;

		display: flex;
		justify-content: center;

		@media (min-width: 1500px) {
			align-items: flex-end;
		}
	}
	.slideCard {
		background: var(--lightest-slate);
		margin-bottom: 32px;
		@media (max-width: 1024px) {
			margin-bottom: 48px;
		}
		@media (max-width: 768px) {
			margin-bottom: 32px;
		}
		@media (max-width: 500px) {
			margin-bottom: 8px;
		}
	}
	.innerSlideCardMedia {
		border-radius: 30px;
		background: var(--white);
		height: 100%;
		@media (min-width: 1024px) {
			/* padding: 0px 16px; */
		}
	}
	.slideTitle {
		background: var(--light-slate);
		border-radius: 30px;
		padding: 8px 16px;
		margin-top: 16px;
		@media (min-width: 1440px) {
			padding: 8px;
			margin-top: 16px;
			margin-bottom: 24px;
		}
		@media (max-width: 768px) {
			padding: 8px;
			margin-top: 8px;
			margin-bottom: 8px;
		}
	}
	.slideTextItem {
		display: flex;
		justify-content: flex-start;
		align-items: initial;
	}
`;

export default swiperStyles;
