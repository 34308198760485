import { Link } from 'gatsby';
import React from 'react';

//logo
import logo from '../../static/icon-text-logo.svg';
import textLogo from '../../static/text-logo.svg';

const Logo = () => {
	return (
		<Link to="/">
			<img alt="Datacorpus" src={logo} style={{ maxHeight: '64px' }} className="logoImage" />
		</Link>
	);
};

export const TextLogo = () => {
	return (
		<Link to="/">
			<img alt="Datacorpus" src={textLogo} style={{ maxHeight: '64px' }} className="logoImage" />
		</Link>
	);
};

export const CareerTextLogo = () => {
	return (
		<Link to="/">
			<img alt="Datacorpus" src={textLogo} style={{ maxHeight: '64px' }} className="careerImage" />
		</Link>
	);
};

export default Logo;
