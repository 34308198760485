// ===================service images=================== //
// Voice & Speech recognition
import deviceSpecific from '../static/SpeechRecognition/deviceSpecific.png';
import languageRecogition from '../static/SpeechRecognition/languageRecognition.png';
import speechRecog from '../static/SpeechRecognition/speechRecog.png';
import sentimentRecog from '../static/SpeechRecognition/sentimentRecognition.png';
import speakerRecog from '../static/SpeechRecognition/speakerRecog.jpg';
// bullets
import device from '../static/SpeechRecognition/bullets/device.svg';
import domain from '../static/SpeechRecognition/bullets/domain.svg';
import language from '../static/SpeechRecognition/bullets/language.svg';
import sentiment from '../static/SpeechRecognition/bullets/sentiment.svg';
import speech from '../static/SpeechRecognition/bullets/speech.svg';
import speaker from '../static/SpeechRecognition/bullets/speaker.svg';

// Image Recognition
import droneDetection from '../static/ImageRecognition/droneDetection.png';
import maskDetection from '../static/ImageRecognition/mask.png';
import plantDetection from '../static/ImageRecognition/plant.png';
import irisDetection from '../static/ImageRecognition/iris.png';
import armedDeviceDetection from '../static/ImageRecognition/armedDevice.png';
import productIdentification from '../static/ImageRecognition/productIdentification.png';
import hpeDetection from '../static/ImageRecognition/hpe.png';
//bullets
import arm from '../static/ImageRecognition/bullets/arm.svg';
import drone from '../static/ImageRecognition/bullets/drone.svg';
import face from '../static/ImageRecognition/bullets/face.svg';
import food_color from '../static/ImageRecognition/bullets/food_color.svg';
import iris from '../static/ImageRecognition/bullets/iris.svg';
import lense from '../static/ImageRecognition/bullets/lense.svg';
import mask from '../static/ImageRecognition/bullets/mask.svg';
import plant from '../static/ImageRecognition/bullets/plant.svg';
import vehicle from '../static/ImageRecognition/bullets/vehicle.svg';
import hpe from '../static/ImageRecognition/bullets/hpe.svg';

// Sound Detection
import babyCry from '../static/SoundDetection/babyCry.jpg';
import carEngine from '../static/SoundDetection/carEngine.png';
import dogBarking from '../static/SoundDetection/dogBarking.png';
import droneSound from '../static/SoundDetection/droneSound.png';
import glassBreaking from '../static/SoundDetection/glassBreaking.png';
import MachineSound from '../static/SoundDetection/MachineSound.jpg';
// bullets
import dronesound from '../static/SoundDetection/bullets/dronesound.svg';
import baby from '../static/SoundDetection/bullets/baby.svg';
import animal from '../static/SoundDetection/bullets/animal.svg';
import car_engine from '../static/SoundDetection/bullets/car_engine.svg';
import machine from '../static/SoundDetection/bullets/machine.svg';
import dog from '../static/SoundDetection/bullets/dog.svg';
import smoke from '../static/SoundDetection/bullets/smoke.svg';
import siren from '../static/SoundDetection/bullets/siren.svg';
import glass from '../static/SoundDetection/bullets/glass.svg';
import env from '../static/SoundDetection/bullets/env.svg';

// Natural Language Processing
import chatBotImg from '../static/NLP/chatBot.png';
import surveyImg from '../static/NLP/survey.png';
import machineTranslation from '../static/NLP/machineTranslation.png';
import textSummerization from '../static/NLP/textSummerization.png';
// bullets
import text from '../static/NLP/bullets/text.svg';
import intent from '../static/NLP/bullets/intent.svg';
import chatbot from '../static/NLP/bullets/chatbot.svg';
import topic from '../static/NLP/bullets/topic.svg';
import survey from '../static/NLP/bullets/survey.svg';

// Sensor
import sensor from '../static/sensor/sensor.svg';
// bullets
import vibration from '../static/sensor/bullets/vibration.svg';
import movement from '../static/sensor/bullets/movement.svg';
import behaviour from '../static/sensor/bullets/behaviour.svg';
import others from '../static/sensor/bullets/others.svg';
import anomaly from '../static/sensor/bullets/anomaly.svg';
import thermal from '../static/sensor/bullets/thermal.svg';

// service box images
import collecting from '../static/serviceBoxImages/collecting.svg';
import filtering from '../static/serviceBoxImages/filtering.svg';
import cleaning from '../static/serviceBoxImages/cleaning.svg';
import annotating from '../static/serviceBoxImages/annotating.svg';
import creating from '../static/serviceBoxImages/creating.svg';
import data_augmenting from '../static/serviceBoxImages/data_augmenting.svg';

//team
import eeshawar from '../static/team/eeshawar.png';
import uday from '../static/team/uday.png';
import vijay from '../static/team/vijay.png';

//blogs
import body_movement from '../static/blogs/body_movement.png';
import data from '../static/blogs/data.jpg';
import machine_learning from '../static/blogs/machine_learning.jpg';

//Trusted By
import swayaan from '../static/trusted_by/swayaan.png';

export const trustedBy = [ swayaan, swayaan, swayaan, swayaan ];

export const navLinks = [
	{ name: 'Home', url: '/' },
	{ name: 'Services', url: '/#services' },
	{ name: 'Team', url: '/#team' },
	{ name: 'Blogs', url: '/#blogs' },
	{ name: 'Career', url: '/#career' },
	{ name: 'Contact Us', url: '/#contact_us' }
];
export const wordsData = [
	{ text: 'creating', color: '#436bda' },
	{ text: 'collecting', color: '#f97439' },
	{ text: 'cleaning', color: '#29bf12' },
	{ text: 'tagging', color: '#ffbd00' },
	{ text: 'annotating', color: '#069092' },
	{ text: 'filtering', color: '#da365f' }
];
export const headerData = {
	title: [ 'expertise in ', 'data for', ' your AI/ML development' ],
	points: [ 'We are super human in AI/ML ', 'We create fast and quality data ' ],
	subPoints: [
		"Don't you have data for your PoC/Prototype? Call us",
		'Getting poor accuracy? Need more data for your ML training? Call us ',
		'We are expertise in creating domain specific data corpus',
		'We are helping AI/ML startup get into faast track on ML development ',
		'Wanna get into production faaster? Struggling on training data? Call us '
	]
};
export const blogsData = [
	{
		title: 'Will artificial intelligence body movement recognition ever rule the world?',
		imageURL: body_movement,
		blogURL: 'https://blog.datacorpus.ai/will-artificial-intelligence-body-movement-recognition-ever-rule-the-world',
		publishedDate:'Oct 3rd, 2021',
		blogSiteURL:'http://blog.datacorpus.ai/',
		wpBlogURL:'https://blog.datacorpus.ai/?p=711'

	},
	{
		title: 'Why data is important in machine learning?',
		imageURL: data,
		blogURL: 'https://blog.datacorpus.ai/why-data-is-important-in-machine-learning',
		publishedDate:'Oct 1st, 2021',
		blogSiteURL:'http://blog.datacorpus.ai/',
		wpBlogURL:'https://blog.datacorpus.ai/?p=713'

	},
	{
		title: 'Is Machine Learning is highly reliable on Data quality and Data Insights?',
		imageURL: machine_learning,
		blogURL: 'https://blog.datacorpus.ai/is-machine-learning-is-highly-reliable-on-data-quality-and-insights',
		publishedDate:'Sep 30th, 2021',
		blogSiteURL:'http://blog.datacorpus.ai/',
		wpBlogURL:'https://blog.datacorpus.ai/?p=709'

	}
];
export const TeamData = [
	{
		imageURL: vijay,
		linkedInURL: 'https://www.linkedin.com/in/vijaya-bhaskar-reddy-25b5582a',
		name: 'Vijay Baskar',
		position: 'Co-Founder'
	},
	{
		imageURL: eeshawar,
		linkedInURL: 'https://www.linkedin.com/in/easwarakarthikkrishnan/',
		name: 'Easwara Karthik',
		position: 'Operations'
	},
	{
		imageURL: uday,
		linkedInURL: 'https://www.linkedin.com/in/udaypulleti/',
		name: 'Uday Kiran',
		position: 'Advisor'
	}
];
export const servicesData = [
	{
		name: 'For Voice & Speech Recognition',
		imageSlider: [
			{ img: speechRecog, title: 'Speech Recognition' },
			// { img: speakerRecog, title: 'Speaker Recognition' },
			{ img: languageRecogition, title: 'Language Recognition' },
			{ img: sentimentRecog, title: 'Sentiment Recognition' },
			{ img: deviceSpecific, title: 'Device specific data' }
		],
		infoList: [
			{
				text: 'Speech Recognition',
				icon: speech
			},
			{
				text: 'Language Recognition',
				icon: language
			},
			{
				text: 'Sentiment Recognition',
				icon: sentiment
			},
			{
				text: 'Device specific data',
				icon: device
			},
			{
				text: 'Speaker Recognition',
				icon: speaker
			},
			{
				text: 'Domain & location specific data',
				icon: domain
			}
		]
	},
	{
		name: 'For Image Recognition',
		imageSlider: [
			{ img: droneDetection, title: 'Drone Detection' },
			{ img: irisDetection, title: 'Iris Detection' },
			{ img: maskDetection, title: 'Mask Detection' },
			{ img: hpeDetection, title: 'Human Posture Detection' },
			{ img: plantDetection, title: 'Plant Desease Detection' },
			{ img: armedDeviceDetection, title: 'Armed Device Detection' },
			{ img: productIdentification, title: 'Product Identification' }
		],
		infoList: [
			{
				text: 'Drone Detection',
				icon: drone
			},
			{
				text: 'Iris Recognition',
				icon: iris
			},
			{
				text: 'Mask Detection',
				icon: mask
			},
			{
				text: 'Human Posture Detection',
				icon: hpe
			},
			{
				text: 'Plant Disease Detection',
				icon: plant
			},
			{
				text: 'Armed Device Detection',
				icon: arm
			},
			{
				text: 'Product Identification',
				icon: lense
			},
			{
				text: 'Face Recognition',
				icon: face
			},
			{
				text: 'Vehicle Detection',
				icon: vehicle
			},
			{
				text: 'Food & Color Detection',
				icon: food_color
			}
		]
	},
	{
		name: 'For Sound & Audio Recognition',
		imageSlider: [
			{ img: droneSound, title: 'Drone sound' },
			{ img: carEngine, title: 'Car engines sound' },
			{ img: glassBreaking, title: 'Glass breaking' },
			// { img: babyCry, title: 'Baby cry' },
			{ img: dogBarking, title: 'Dog Barking' },
			{ img: MachineSound, title: 'Machines sound' }
		],
		infoList: [
			{
				text: 'Drone sound',
				icon: dronesound
			},
			{
				text: 'Car engines sound',
				icon: car_engine
			},
			{
				text: 'Glass breaking',
				icon: glass
			},
			{
				text: 'Dog Barking',
				icon: dog
			},
			{
				text: 'Machines sound',
				icon: machine
			},
			{
				text: ' Baby cry',
				icon: baby
			},
			{
				text: 'Siren sounds',
				icon: siren
			},
			{
				text: 'Smoke alarm',
				icon: smoke
			},
			{
				text: 'Animals Sound',
				icon: animal
			},
			{
				text: 'Environment Sound',
				icon: env
			}
		]
	},
	{
		name: 'For Natural Language Processing',
		imageSlider: [
			{ img: sentimentRecog, title: 'Sentiment Analysis' },
			{ img: machineTranslation, title: 'Machine Translation' },
			{ img: textSummerization, title: 'Text Summarization' },
			{ img: chatBotImg, title: 'Chatbot & Virtual assistants' },
			{ img: surveyImg, title: 'Survey Analysis' }
		],
		infoList: [
			{
				text: 'Sentiment Analysis',
				icon: sentiment
			},
			{
				text: 'Machine Translation',
				icon: machine
			},
			{
				text: 'Text Summarization',
				icon: text
			},
			{
				text: 'Chatbot & Virtual assistants ',
				icon: chatbot
			},
			{
				text: 'Survey Analysis',
				icon: survey
			},
			{
				text: 'Topic Classification',
				icon: topic
			},
			{
				text: 'Intent Classification',
				icon: intent
			}
		]
	},
	{
		name: 'For Sensors',
		imageSlider: [ { img: sensor, title: 'Vibration Data' } ],
		infoList: [
			{
				text: 'Vibration Data',
				icon: vibration
			},
			{
				text: ' Thermal data',
				icon: thermal
			},
			{
				text: ' Movement data',
				icon: movement
			},
			{
				text: 'Behaviour data',
				icon: behaviour
			},
			{
				text: ' Anomaly data',
				icon: anomaly
			},
			{
				text: 'Body Movement Sensor',
				icon: hpe
			},
			{
				text: 'All other sensor data',
				icon: others
			}
		]
	}
];

export const secondServices = [
	{
		title: 'Data Collection',
		imageURL: collecting,
		body:
			'Tell us your requirements, we collect data from all sources where others can not collect easily & quickly. Either open source or licenced, we are here to collect the data for your requirements.'
	},
	{
		title: 'Data Creation',
		imageURL: creating,
		body:
			'We are expertise in creating new hand crafted corpus for your specific domain or application for ML training. Rather than spending more time on cleaning, training, tuning models or corpus adaptation using irrelevant corpus, lets build quickly new corpus for your specific domain & application which gives high accuracy and also saves time.'
	},
	{
		title: 'Data Augumentation',
		imageURL: data_augmenting,
		body:
			"Most of the organizations don't have a good amount of training data for ML training and generate more data which has diversity, fidelity, grammatical and quality issues. Our team expertise in generating a large volume of data corpus from your small amount of data and gets you the required size for ML training."
	},
	{
		title: 'Data Cleaning',
		imageURL: cleaning,
		body:
			'Sometimes cleaning the data might take longer time for your ML training. We clean and provide the right data for your ML development with high levels of data security regulations. We clean all types of data such as Text, Image, Video, Speech & Audio and others.'
	},
	{
		title: 'Data Fitering',
		imageURL: filtering,
		body:
			'Your data having too much noise? need to extract specific content or information from a large amount of data? Having difficulties in applying a filter mechanism? We are here to solve the issues.'
	},
	{
		title: 'Data Annotation',
		imageURL: annotating,
		body:
			'Our Machine Learning based model automatically does the accurate annotation as well as we have a dedicated expert team manually inspect and annotate the data with zero error.'
	}
];
export const footerData = {
	linkedIn: 'https://www.linkedin.com/company/datacorpus',
	instagram: 'https://instagram.com/datacorpus.ai?utm_medium=copy_link',
	email:
		'mailto:careers@datacorpus.ai?cc=vijay@datacorpus.ai,karthik@datacorpus.ai,prabhu@datacorpus.ai,deepeka@datacorpus.ai,uday@datacorpus.ai&bcc=contact@datacorpus.ai&subject=From%20Datacorpus',
	telephone: 'tel: +91 7406463740',
	display_mobile: '+91 7406463740',
	display_email: 'info@datacorpus.ai',
	address: 'Bangalore, India'
};

export const careerData = {
	email_link:
		'mailto:careers@datacorpus.ai?cc=vijay@datacorpus.ai,karthik@datacorpus.ai,prabhu@datacorpus.ai,deepeka@datacorpus.ai,uday@datacorpus.ai&bcc=contact@datacorpus.ai&subject=Interested%20in%20Datacorpus',
	display_email: 'careers@datacorpus.ai'
};
