import React, { Fragment, useState, useEffect } from 'react';
import Footer from './Footer';
import Nav from './Nav';
import GlobalStyle from '../styles/GlobalStyle';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { darkNavy } from '../styles/variables';
import { CssBaseline } from '@material-ui/core';
import Loader from './Loader';

const theme = createTheme({
	typography: {
		fontFamily: `'Poppins','Comfortaa', Lato`,
		color: darkNavy,
		fontSize: 14,
		fontWeightLight: 400,
		fontWeightRegular: 600,
		fontWeightMedium: 600,
		h1: { color: darkNavy },
		h2: { color: darkNavy },
		h3: { color: darkNavy },
		h4: { color: darkNavy },
		h5: { color: darkNavy },
		h6: { color: darkNavy },
		subtitle1: { color: darkNavy },
		subtitle2: { color: darkNavy },
		body1: { color: darkNavy },
		body2: { color: darkNavy },
		button: { color: darkNavy },
		caption: { color: darkNavy }
	},
	palette: {
		primary: { main: darkNavy, contrastText: '#ccd6f6' },
		secondary: { main: '#069092' },
		text: {
			primary: darkNavy,
			secondary: '#069092',
			disabled: '#069092',
			hint: '#069092',
			divider: '#069092'
		},
		background: {
			// paper:'#d9dbe5'
			paper: '#e5e7f0'
		}
	}
});

const Layout = ({ children, active, setActive, location }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	useEffect(
		() => {
			if (isLoading) {
				return;
			}

			if (location.hash) {
				const id = location.hash.substring(1); // location.hash without the '#'
				setTimeout(() => {
					const el = document.getElementById(id);
					if (el) {
						el.scrollIntoView();
						el.focus();
					}
				}, 0);
			}
		},
		[ isLoading ]
	);
	return (
		<MuiThemeProvider theme={theme}>
			<Helmet>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
				<link
					href="https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&family=Open+Sans&display=swap"
					rel="stylesheet"
				/>
				<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" />
				<title>Data Corpus</title>
			</Helmet>
			<CssBaseline />
			<GlobalStyle />
			{isLoading ? (
				<Loader finishLoading={() => setIsLoading(false)} />
			) : (
				<Fragment>
					<Nav active={active} setActive={setActive} />
					{children}
					<Footer />
				</Fragment>
			)}
		</MuiThemeProvider>
	);
};

export default Layout;
